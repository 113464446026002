/* Test.css */
.test-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.test-title {
  text-align: center;
  color: #333;
}

.shuffle-container {
  margin: 20px 0;
  text-align: center;
}

.shuffle-label {
  font-weight: bold;
  color: #666;
}

.shuffle-checkbox {
  margin-left: 10px;
}

.test-select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.navigation-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
}

.score-display, .incorrect-answers-display {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  color: #333;
}

.test-completion {
  text-align: center;
}

.test-results {
  font-size: 18px;
  margin-bottom: 20px;
}
