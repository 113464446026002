/* src/Footer.css */

.footer {
    background-color: #000000;
    color: white;
    padding: 15px;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer-stats,
  .footer-contact,
  .footer-rights {
    margin: 10px 0;
    font-size: 0.9rem;
  }
  
  .footer-contact a {
    color: #4d90fe; /* A light blue color for links */
    text-decoration: none;
  }
  
  .footer-contact a:hover {
    text-decoration: underline;
  }
  
  /* Add responsiveness if necessary */
  @media (max-width: 768px) {
    .footer-stats,
    .footer-contact,
    .footer-rights {
      font-size: 0.8rem;
    }
  }
  