.background-container {
  display: flex;
  justify-content: center;
  align-items: center;
    height: 100vh;
    background-image: url('/Users/bahamustafa/dmv-quiz/src/124.webp'); /* Replace with your image path */
    background-size: cover;
    
  }
  
  .home-container {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.9); /* Slight transparency */
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .content-overlay {
    padding: 20px;
  }
  
  h1 {
    margin-bottom: 20px;
    color: #333;
  }
  
  p {
    margin-bottom: 30px;
    color: #555;
  }
  
  .start-btn, .inspection-btn {
    display: inline-block;
    background-color: #007bff;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin: 5px;
    text-decoration: none; /* Remove underline from link */
    transition: background-color 0.3s ease;
  }
  
  .start-btn:hover, .inspection-btn:hover {
    background-color: #0056b3;
  }
  
  .inspection-btn {
    background-color: #28a745; /* Different color for secondary action */
  }
  
  .inspection-btn:hover {
    background-color: #218838;
  }
  