/* Inspections.css */

.inspection-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.inspection-category h3 {
  cursor: pointer;
  background-color: #007bff;
  color: #ffffff;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.inspection-category h3:hover,
.inspection-category h3.active {
  background-color: #0056b3;
}

.inspection-item {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.inspection-item img,
.inspection-item iframe {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.inspection-item p {
  margin-top: 10px;
}

.inspection-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 10px;
}
