/* Question.css */
div {
  margin-bottom: 20px;
}

h3 {
  color: #333;
  margin-bottom: 15px;
}

button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 2px solid transparent;
  border-radius: 5px;
  background-color: #f0f0f0;
  color: #333;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  background-color: #e9e9e9;
}

.correct-answer {
  border-color: #37c882;
  background-color: #16330b;
}

button.correct-answer:hover {
  background-color: #2b0571;
}
