/* App.css */

body, html {
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
  margin: 0;
  padding: 0;
}


.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  background-color: #007bff;
  color: #ffffff;
  padding: 20px 0;
  text-align: center;
  font-size: 24px;
}

main {
  flex: 1;
  padding: 20px;
}

footer {
  background-color: #333;
  color: #ffffff;
  text-align: center;
  padding: 10px 0;
}

/* Responsive design */
@media (max-width: 768px) {
  header, footer {
    font-size: 20px;
  }
}


/* App.css or Navbar.css */

/* Enhancements for the navigation bar */

nav ul {
  list-style-type: none;
  padding: 20px 0; /* Increase vertical padding */
  margin: 0;
  display: flex;
  background-color: #000000;
  justify-content: center;
  align-items: center; /* Center items vertically */
  position: sticky; /* Make the navbar sticky */
  top: 0; /* Stick to the top of the viewport */
  z-index: 1000; /* Ensure it's above other content */
}

nav ul li {
  margin: 0 20px; /* Increase spacing between links for a "thicker" feel */
}

nav ul li a {
  color: white;
  text-decoration: none;
  padding: 10px 20px; /* Increase padding for a larger click area */
  font-size: 16px; /* Optional: Increase font size for better readability */
}

nav ul li a:hover, nav ul li a:focus {
  background-color: #c8c9ca;
  border-radius: 5px; /* Optional: Rounded corners on hover/focus */
}

/* Ensure content doesn't hide behind the sticky navbar */
.body-content {
  padding-top: 80px; /* Adjust based on the final height of your navbar */
}

